.show-sensor {
  font-weight: bold;
  display: block;
}

.hide-sensor {
  display: none;
}

.sen-red {
  fill: #dc3545 !important;
}

.sen-amber {
  fill: #ffc000 !important;
}

.sen-green_dark {
  fill: #00a400 !important;
}

.line-black {
  stroke: black !important;
}

.line-light-blue {
  stroke: #00bfff !important;
}

.line-dark-blue {
  stroke: #2776bb !important;
}

.line-red {
  stroke: #ff003f !important;
}

.line-dark-red {
  stroke: #950000 !important;
}

.line_pink {
  stroke: #d400ff !important;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.star {
  animation: rotate 10s linear infinite;
}
.star {
  animation: rotate 10s linear infinite;
}
.star1 {
  transform-origin: 3038.0422px 443.80574px;
}
.star2 {
  transform-origin: 3054.3531px 443.80574px;
}
.star3 {
  transform-origin: 3070.7573px 443.80574px;
}
.star4 {
  transform-origin: 3086.9255px 443.80574px;
}
.star5 {
  transform-origin: 3143.2399px 443.80574px;
}
.star6 {
  transform-origin: 3159.5508px 443.80574px;
}
.star7 {
  transform-origin: 3175.955px 443.80574px;
}
.star8 {
  transform-origin: 3192.1232px 443.80574px;
}
.star9 {
  transform-origin: 3207.8231px 443.80574px;
}
.star10 {
  transform-origin: 3224.134px 443.80574px;
}
.star11 {
  transform-origin: 3240.5382px 443.80574px;
}
.star12 {
  transform-origin: 3256.7064px 443.80574px;
}
