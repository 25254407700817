@import '../../styles/variables';
@import '../../styles/global';
@import '../../styles/media-queries';

.imageWrapper {
  margin-bottom: 20px;
}

.sidebar {
  min-width: 250px;
  height: auto;
  top: 0;
  left: 0;
  position: relative;
  display: block;
  /* top layer */
  z-index: 999;
  background-color: $white;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  min-height: 100%;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);

  .dismiss {
    display: none;
  }

  .sidebarHeader {
    padding: 25px;
    .arrowCollapse {
      cursor: pointer;
    }
  }

  .logo {
    margin-right: 40px;
  }

  .selectedLogoImg {
    max-height: 50px;
    max-width: 150px;
  }

  .sidebarTop {
    position: fixed;
    max-width: 250px;
  }

  .sidebarTopClose {
    position: fixed;
    min-width: 80px;
    max-width: 250px;
  }

  ul li {
    padding: 8px;
    padding-left: 25px;
  }

  ul li a {
    @include font(2);
    color: $grey !important;
  }

  ul li a:hover {
    text-decoration: none;
    color: $lightBlue !important;
  }

  .activeLink {
    border-left: solid 4px $lightBlue;
    padding-left: 21px;
    span {
      @include font(2, 'semi-bold');
      color: $lightBlue !important;
    }
  }

  .profileName {
    color: $grey !important;
  }

  .sidebarFooter {
    position: fixed;
    bottom: 3%;
    left: 15px;
    min-width: 250px;

    .footerText {
      @include font(2, 'medium');
      // padding-left: 5px;
      .userName {
        color: $darkBlue !important;
      }

      .profileName {
        color: $grey !important;
        font-size: 13px;
        font-weight: normal;
      }
      .versionNo {
        color: $grey !important;
        @include font(1);
        display: block;
        margin-top: 10px;
      }
    }
  }
  .copyRight {
    position: fixed;
    bottom: 0%;
    left: 15px;
    text-align: center;
    min-width: 250px !important;
    .copyRightText {
      @include font(1, 'medium');
      color: $grey !important;
      font-size: 10px;
      font-weight: normal;
    }
  }
}

.active {
  min-width: 80px !important;
  max-width: 80px !important;
  text-align: center;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;

  .sidebarHeader a {
    display: none;
  }

  .arrowCollapse {
    padding-right: 5px;
    cursor: pointer;
  }

  ul li a img {
    margin-right: 0;
    display: block;
    margin-bottom: 5px;
  }

  ul {
    padding-top: 20px;
  }

  ul li a span {
    display: none;
  }

  .footerText span {
    display: none;
  }

  .copyRight {
    display: none;
  }
}

@media (max-width: 768px) {
  .dismiss {
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    display: block !important;
  }

  .sidebar {
    width: 250px;
    position: fixed;
    top: 56px;
    left: -250px;
    height: 100vh;
    z-index: 999;
    background-color: $white;
    transition: all 0.3s;
    overflow-y: scroll;
    box-shadow: none;
    .sidebarFooter {
      left: -250px !important;
    }

    .copyRight {
      display: none;
    }
  }

  .sidebarTop {
    min-width: 250px;
  }

  .sidebarTopClose {
    min-width: 250px !important;
  }

  .active {
    left: 0;
    min-width: 250px !important;
    max-width: 250px !important;
    text-align: center;

    .sidebarHeader a {
      display: block;
    }

    .sidebarHeader a img {
      float: left;
    }

    .arrowCollapse {
      display: none;
    }

    ul li a span {
      display: block;
      text-align: left;
    }

    ul li a img {
      float: left;
      clear: left;
    }

    .footerText span {
      display: flex;
      float: left;
    }
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
}
