@import '../../../styles/variables';
@import '../../../styles/global';

.buildVersionWrapper {
  .contentWrapper {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 20px;

    .buildVersionContent {
      width: 100%;
      border-radius: 5px;
      background-color: $white;
      padding: 40px;
      box-sizing: border-box;
      position: relative;
    }
    .releaseVersionHeading {
      margin-top: 30px;
    }
    .alert {
      background-color: $alert;
      padding: 3px;
      border-radius: 3px;
    }
  }
}
