@import '../../../styles/variables';
@import '../../../styles/global';

.termsAndConditionWrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1500;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.7);

  .contentWrapper {
    width: 90vw;
    height: 80vh;
    padding: 30px;
    z-index: 10001;
    box-sizing: border-box;
    background-color: $white;
    border-radius: 4px;
    @include boxShadow();
    pointer-events: initial;
    position: relative;

    .content {
      width: 100%;
      height: 90%;

      .embedObject {
        height: 100%;
        width: 100%;
      }
    }

    .agreeMessage {
      margin-top: 10px;
      @include font(2, 'bold');
      color: $darkBlue !important;
      justify-content: space-between;
      flex-wrap: wrap;
      text-align: center;
    }

    .buttonHolder {
      margin-top: 15px;
      text-align: center;
      justify-content: space-between;
      flex-wrap: wrap;

      .button {
        margin-right: 5px;
      }

      .declineBtn {
        background-color: $white !important;
        border: 1px solid $lightBlue !important;
        color: $darkBlue !important;
      }
    }

    @media (max-width: 700px) {
      .agreeMessage {
        margin-top: 10px;
        @include font(1, 'bold');
        color: $darkBlue !important;
        justify-content: space-between;
        flex-wrap: wrap;
        text-align: center;
      }

      .content {
        width: 100%;
        height: 85%;
      }
      .buttonHolder {
        .button {
          margin-right: 5px;
          width: 100px;
        }

        .declineBtn {
          background-color: $white !important;
          border: 1px solid $lightBlue !important;
          color: $darkBlue !important;
          width: 100px;
        }
      }
    }

    @media (max-width: 530px) {
      .agreeMessage {
        margin-top: 10px;
        font-size: 0.65rem !important;
        color: $darkBlue !important;
        justify-content: space-between;
        flex-wrap: wrap;
        text-align: center;
      }
      .content {
        width: 100%;
        height: 80%;
      }
    }
  }
}
