@import '../../styles/variables';
@import '../../styles/global';
@import '../../styles/media-queries';

.wrapper {
  display: flex;
  align-items: stretch;
  width: 100%;

  .contentWrapper {
    background-color: $greyBackground;
    height: 100%;
    width: 100%;
    padding: 40px;
    min-height: 100vh;
    transition: all 0.3s;

    .noticeContainer {
      .noticeBoard {
        position: absolute;
        top: 18px;
        right: 30px;
        z-index: 999999;
        img {
          margin-right: 5px;
          padding-bottom: 2px;
          width: 22px;
          height: 22px;
          cursor: pointer;
        }
      }

      .showImage {
        display: block;
      }
      .hideImage {
        display: none;
      }

      .circle {
        @include font(2, 'medium');
        position: absolute;
        top: 10px;
        right: 29px;

        background: $danger;
        line-height: 18px !important;
        height: 18px;
        width: 18px;
        border-radius: 50%;
        font-size: 10px !important;
        color: $white !important;
        text-align: center;
        // border: 1px solid $white;
        z-index: 999999;
      }
    }
  }
}

.envBar {
  height: 50px;
  text-transform: uppercase;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 999;
}

.bgColorDev {
  background-color: #ff8441;
}

.bgColorTest {
  background-color: #0ec676;
}

.bgColorStage {
  background-color: #9a58d8;
}

@media (max-width: 768px) {
  .wrapper {
    display: block;
  }

  .contentWrapper {
    overflow-x: hidden;
    padding-left: 30px !important;

    .sidebarCollapse {
      position: absolute;
      top: 2%;
      left: 4%;
      z-index: 190000;
    }
  }

  .overlay {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 998;
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }
  .active {
    display: block;
    opacity: 1;
  }

  .contentWrapper {
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
}
